import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { IMarkdownQuery } from '../types/article'
import Head from '../components/head'
import Layout from '../components/layout'

export interface IArticlePageProps {
  data: IMarkdownQuery
}

const ArticlePage: FunctionComponent<IArticlePageProps> = ({ data }) => {
  return (
    <Layout>
      <Head
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.excerpt}
      />

      <article>
        <h1 className='page-title'>
          {data.markdownRemark.frontmatter.title}

          <button onClick={() => window?.history.back()} title='Back'>
            &times;
          </button>
        </h1>

        <Img
          fluid={data.markdownRemark.frontmatter.photo.childImageSharp.fluid}
          style={{
            maxHeight: '90vh',
          }}
          imgStyle={{
            objectFit: 'contain',
          }}
        />

        <div
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
      </article>
    </Layout>
  )
}

export default ArticlePage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      excerpt
      frontmatter {
        photo {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        title
      }
      html
    }
  }
`
